import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  path, pathOr, prop, props, length, join, toString, filter, contains, defaultTo,
  equals, map, pipe, sum, subtract, gt, all, pluck, head, tail, toUpper, flatten,
  propOr, repeat, splitEvery, append, init, update, and, anyPass, or, isEmpty,
  values, split, without, concat, find, propEq
} from 'ramda'
import moment from 'moment'
// import * as jsPDF from 'jspdf'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { replace, goBack } from 'connected-react-router'
import { Creators as Actions } from '../actions'
import { nl2brArray, formatDate, findById } from '../util/helpers'
import {
  activeCouriersSelector,
  orderSelector,
  orderShipmentsSelector,
  orderHistorySelector,
} from '../util/selectors'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import { blueGrey, yellow } from '@material-ui/core/colors'
import Tooltip from '@material-ui/core/Tooltip'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import IconPrint from '@material-ui/icons/Print'
import IconAdd from '@material-ui/icons/Add'
import IconLaunch from '@material-ui/icons/Launch'
import IconSync from '@material-ui/icons/Sync'
import IconDelete from '@material-ui/icons/Delete'
import IconDone from '@material-ui/icons/Done'
import IconFlightTakeoff from '@material-ui/icons/FlightTakeoff'
import IconVisibility from '@material-ui/icons/Visibility'
import IconWarning from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import withMobileDialog from '@material-ui/core/withMobileDialog'

// React Grid
import {
  PagingState, IntegratedPaging,
} from '@devexpress/dx-react-grid'
import {
  Grid as ReactGrid,
  Table, TableHeaderRow,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui'

// Components
import LinkButton from '../components/LinkButton'
import Header from '../components/Header'
import GoBackButton from '../components/GoBackButton'
import RefreshButton from '../components/RefreshButton'
import Loading from '../components/Loading'
import GridOrderItems from '../components/GridOrderItems'
import CourierFormApc from '../components/CourierFormApc'
import CourierFormDhl from '../components/CourierFormDhl'
import CourierFormDpd from '../components/CourierFormDpd'
import CourierFormUps from '../components/CourierFormUps'
import CourierFormParcelforce from '../components/CourierFormParcelforce'
import CourierFormPackfleet from '../components/CourierFormPackfleet'
import CourierFormOffline from '../components/CourierFormOffline'
import Empty from '../components/Empty'
import GridDateTypeProvider from '../components/GridDateTypeProvider'
import GridUrlTypeProvider from '../components/GridUrlTypeProvider'
import GridCourierTrackingTypeProvider from '../components/GridCourierTrackingTypeProvider'
import DialogOrderStatusChange from '../components/DialogOrderStatusChange'
import PrintButton from '../components/PrintButton'
import PrintWrapper from '../components/PrintWrapper'
import PrintOrder from '../components/PrintOrder'

const canAllocate = item => {
  const toFulfill = subtract(
    prop('ordered', item),
    sum(props(['allocated', 'shipped'], item))
  )
  const inStock = prop('items_in_stock', item)
  return and(
    gt(toFulfill, 0),
    gt(inStock, 0),
  )
}

const canDeallocate = item => {
  return gt(prop('allocated', item), 0)
}

const canShip = item => {
  return gt(prop('allocated', item), 0)
}

const rowSelectionEnabled = row => {
  return anyPass([
    canAllocate,
    canDeallocate,
    canShip,
  ])(row)
}

const styles = theme => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  flex: {
    flex: 1,
  },
  gutterTop: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  paperReserved: {
    backgroundColor: blueGrey[500]
  },
  column: {
    height: '100%',
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  courierForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  courierWarningIcon: {
    verticalAlign: 'inherit',
    marginRight: theme.spacing(0.5),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    minWidth: 0,
    marginLeft: theme.spacing(1),
    '&:first-child': {
      marginLeft: 0,
    }
  },
  italic: {
    fontStyle: 'italic'
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: yellow['A400']
  }
})


class Order extends Component {
  state = {
    orderReference: path(['params', 'orderReference'], this.props.match),
    // Change Status
    changingStatus: false,
    orderStatusId: 0,
    orderStatusComment: '',
    // Adjust stock
    allocateItems: [],
    deallocateItems: [],
    // Shipping
    shippingOptionsEl: null,
    shippingCourier: null,
    shippingItems: [],
    shippingParcels: [],
    shippingParcelsChanged: false,
    shippingInstructions: '',
    clearWarehouseInstructions: true,
    // APC
    apcCollectionDate: moment().format('YYYY-MM-DD'),
    apcServiceCode: '',
    // DHL
    dhlCollectionJobNumber: '',
    dhlServiceCode: '',
    dhlInstructions1: '',
    dhlInstructions2: '',
    dhlSafePlace1: '',
    dhlSafePlace2: '',
    // DPD
    dpdCollectionDate: moment().format('YYYY-MM-DD'),
    dpdServiceCode: '',
    dpdSmsNotifications: true,
    dpdExtendedLiability: false,
    // Parcelforce
    parcelforceCollectionDate: moment().format('YYYY-MM-DD'),
    parcelforceServiceCode: '',
    parcelforceSaturdayDelivery: false,
    parcelforceSmsNotifications: true,
    parcelforceEnhancedCompensation: 0, // 0-5 values
    // Packfleet
    packfleetCollectionDate: moment().format('YYYY-MM-DD'),
    packfleetCollectionId: '',
    packfleetDeliveryDate: '',
    packfleetServiceCode: '',
    packfleetSmsNotifications: true,
    packfleetExtendedLiability: false,
    packfleetPinCode: false,
    // Delete
    deleting: false,
    unreserveProducts: []
  }

  componentDidMount() {
    this.handleMount()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Retrieved/Updated?
    if (!equals(this.props.data, nextProps.data)) {
      const { data: { order_status_id, delivery_instructions } } = nextProps
      this.setState({
        orderStatusId: toString(order_status_id),
        shippingInstructions: delivery_instructions,
      })
    }

    // Status changed?
    if (
      this.props.uiLoadingChangeStatus !== nextProps.uiLoadingChangeStatus
      && !nextProps.uiLoadingChangeStatus
      && !nextProps.error
    ) {
      this.toggleChangingStatus()
      this.setState({
        orderStatusComment: '',
      })
    }

    // Items selected or changed?
    if (
      !equals(this.props.orderItemsSelection, nextProps.orderItemsSelection)
      || (
        this.props.uiLoadingAdjust !== nextProps.uiLoadingAdjust
        && !nextProps.uiLoadingAdjust
        && !nextProps.error
      )
    ) {
      const selection = prop('orderItemsSelection', nextProps)
      let allocateItems = []
      let deallocateItems = []
      let shippingItems = []
      let shippingParcels = []

      if (length(selection)) {
        const orderItems = pathOr([], ['data', 'order_items'], nextProps)
        const selectedItems = map(index => orderItems[index], selection)

        // Set allowed allocate products
        if (all(canAllocate)(selectedItems)) {
          allocateItems = selectedItems
        }

        // Set allowed deallocate products
        if (all(canDeallocate)(selectedItems)) {
          deallocateItems = selectedItems
        }

        // Set allowed shipping products
        if (all(canShip)(selectedItems)) {
          shippingItems = selectedItems

          // Set no. of parcels
          shippingParcels = pipe(
            // any items not yet shipped?
            map(item => repeat(prop('weight', item), prop('allocated', item))),
            // get total items
            flatten,
            // TODO env config MAX_PARCEL_SIZE ???
            // X items per parcel
            splitEvery(12),
            // sum total parcel weight
            map(pipe(sum, Math.round)),
          )(shippingItems)
        }
      }

      this.setState({
        allocateItems,
        deallocateItems,
        shippingItems,
        shippingParcels,
      })
    }

    // Courier Services retrieved?
    if (!equals(this.props.courierServices, nextProps.courierServices)) {
      const { shippingCourier } = this.state

      // Set default courier service
      const serviceCode = pipe(
        pluck('code'),
        filter(x => contains(x, [
          // APC
          'APCLP16', // UK
          'APCTDAY', // Highlands
          'APCTDLP', // Highlands
          // TODO Northern Ireland
          'APCROAD', // Ireland
          'APCINTR', // International
          // DHL
          '1', // UK Next Day
          // DPD
          '1^32',
          '2^32',
          '1^12',
          '2^12',
          '1^19',
          '2^19',
          // Parcelforce,
          'SND',
          'SUP',
          'EPH',
          // Packfleet
          'nextDay',
        ])),
        head,
        defaultTo('')
      )(nextProps.courierServices)

      if (serviceCode) {
        if (equals(shippingCourier, 'apc')) {
          this.setState({
            apcServiceCode: serviceCode,
          })
        } else if (equals(shippingCourier, 'dhl') && !this.state.dhlServiceCode) {
          this.setState({
            dhlServiceCode: serviceCode,
          })
        } else if (equals(shippingCourier, 'dpd')) {
          this.setState({
            dpdServiceCode: serviceCode,
          })
        } else if (equals(shippingCourier, 'parcelforce') && !this.state.parcelforceServiceCode) {
          this.setState({
            parcelforceServiceCode: serviceCode,
          })
        } else if (equals(shippingCourier, 'packfleet') && !this.state.packfleetServiceCode) {
          this.setState({
            packfleetServiceCode: serviceCode,
          })
        }
      }
    }

    // Courier Collections retrieved?
    if (!equals(this.props.courierCollections, nextProps.courierCollections)) {
      const { shippingCourier } = this.state
      if (equals(shippingCourier, 'packfleet') && !this.state.packfleetCollectionId) {
        const collectionId = pipe(
          head,
          prop('id'),
          defaultTo('')
        )(nextProps.courierCollections)
        this.setState({
          packfleetCollectionId: collectionId,
        })
      }
    }

    // Shipped?
    if (
      this.props.uiLoadingShip !== nextProps.uiLoadingShip
      && !nextProps.uiLoadingShip
      && !nextProps.error
    ) {
      this.toggleShippingClose()
      this.setState({
        shippingItems: [],
        shippingParcels: [],
        shippingParcelsChanged: false,
        shippingInstructions: '',
        clearWarehouseInstructions: true,
        apcCollectionDate: moment().format('YYYY-MM-DD'),
        apcServiceCode: '',
        dhlCollectionJobNumber: '',
        dhlServiceCode: '',
        dhlInstructions1: '',
        dhlInstructions2: '',
        dhlSafePlace1: '',
        dhlSafePlace2: '',
        dpdCollectionDate: moment().format('YYYY-MM-DD'),
        dpdServiceCode: '',
        dpdSmsNotifications: true,
        dpdExtendedLiability: false,
        parcelforceCollectionDate: moment().format('YYYY-MM-DD'),
        parcelforceServiceCode: '',
        parcelforceSaturdayDelivery: false,
        parcelforceSmsNotifications: true,
        parcelforceEnhancedCompensation: 0,
        // packfleetCollectionDate: moment().format('YYYY-MM-DD'),
        packfleetCollectionId: '',
        packfleetDeliveryDate: '',
        packfleetServiceCode: '',
        packfleetSmsNotifications: true,
        packfleetExtendedLiability: false,
        packfleetPinCode: false,
      })

      // Download label
      // const shipment = head(nextProps.orderShipments)
      // if (shipment) {
      //   const consignmentNumber = prop('courier_reference', shipment)
      //   const shipmentItems = prop('shipment_items', shipment)
      //   let labels = []
      //
      //   shipmentItems.forEach(parcel => {
      //     if (parcel.label) {
      //       labels.push(`data:image/jpeg;base64,${parcel.label}`)
      //     }
      //   })
      //
      //   if (!isEmpty(labels)) {
      //     let labelPdf = new jsPDF({
      //       orientation: 'landscape',
      //       unit: 'in',
      //       format: [6, 4]
      //     })
      //
      //     labels.forEach((label, key) => {
      //       if (key !== 0) labelPdf.addPage()
      //       labelPdf.addImage(label, 'PNG', 0, 0, 6, 4)
      //     })
      //
      //     labelPdf.save(`${consignmentNumber}.pdf`)
      //   }
      // }
    }

    // Order deleted?
    if (
      !equals(this.props.data, nextProps.data)
      && !path(['data', 'id'], nextProps)
    ) {
      this.props.replace('/orders')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return or(
      !equals(this.props, nextProps),
      !equals(this.state, nextState)
    )
  }

  componentWillUnmount() {
    this.props.resetOrderState()
  }

  handleMount = () => {
    this.props.getOrderAttempt(this.state.orderReference)
  }

  toggleChangingStatus = () => {
    this.setState({
      shippingOptionsEl: null,
      changingStatus: !this.state.changingStatus,
    })
  }

  toggleShippingOptions = event => {
    this.setState({
      shippingOptionsEl: this.state.shippingOptionsEl ? null : event.currentTarget,
    })
  }

  toggleShippingOpen = courier => {
    this.setState({
      shippingOptionsEl: null,
      shippingCourier: courier,
    }, () => {
      if (equals(courier, 'apc')) {
        this.handleApcCourierServiceSubmit()
      } else if (equals(courier, 'dhl')) {
        this.handleDhlCourierServiceSubmit()
      } else if (equals(courier, 'dpd')) {
        this.handleDpdCourierServiceSubmit()
      } else if (equals(courier, 'parcelforce')) {
        this.handleParcelforceCourierServiceSubmit()
      } else if (equals(courier, 'packfleet')) {
        this.handlePackfleetCourierServiceSubmit()
      }
    })
  }

  toggleShippingClose = () => {
    this.setState({
      shippingCourier: null,
      shippingParcelsChanged: false,
    })
  }

  toggleDeleting = () => {
    this.setState({
      deleting: !this.state.deleting,
    })
  }

  handleUnreserveChange = event => {
    if (event.target.checked) {
      this.setState({
        unreserveProducts: concat([event.target.value], this.state.unreserveProducts)
      })
    } else {
      this.setState({
        unreserveProducts: without([event.target.value], this.state.unreserveProducts)
      })
    }
  }

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleStatusChangeSubmit = () => {
    const { changeOrderStatusAttempt } = this.props
    const { orderReference, orderStatusId, orderStatusComment } = this.state

    changeOrderStatusAttempt(orderReference, {
      order_status_id: Number(orderStatusId),
      comment: orderStatusComment,
    })
  }

  handleAllocateSubmit = () => {
    const { adjustOrderItemsStockAttempt } = this.props
    const { orderReference, allocateItems } = this.state
    const products = map(item => {
      return {
        sku: item.sku,
        kit: pathOr('', ['kit', 'sku'], item)
      }
    })(allocateItems)
    adjustOrderItemsStockAttempt(orderReference, products)
  }

  handleDeallocateSubmit = () => {
    const { adjustOrderItemsStockAttempt } = this.props
    const { orderReference, deallocateItems } = this.state
    const products = map(item => {
      return {
        sku: item.sku,
        kit: pathOr('', ['kit', 'sku'], item)
      }
    })(deallocateItems)
    adjustOrderItemsStockAttempt(orderReference, products, true)
  }

  handleClearWarehouseInstructionsChange = event => {
    this.setState({
      clearWarehouseInstructions: event.target.checked,
    })
  }

  handleParcelChange = key => event => {
    const weight = Math.max(1, Number(event.target.value))
    const parcels = update(key, weight, this.state.shippingParcels)
    this.setState({
      shippingParcels: parcels,
      shippingParcelsChanged: true
    })
  }

  handleAddParcel = () => {
    const parcels = append(1, this.state.shippingParcels)
    this.setState({
      shippingParcels: parcels,
      shippingParcelsChanged: true
    })
  }

  handleRemoveParcel = () => {
    const parcels = init(this.state.shippingParcels)
    this.setState({
      shippingParcels: parcels,
      shippingParcelsChanged: true
    })
  }

  // APC
  handleApcCourierServiceSubmit = () => {
    const { getCourierServicesAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      shippingParcels,
      apcCollectionDate,
    } = this.state
    this.setState({
      shippingParcelsChanged: false
    })
    getCourierServicesAttempt(shippingCourier, {
      order_reference: orderReference,
      collection_date: apcCollectionDate,
      parcels: shippingParcels,
    })
  }

  handleApcCollectionDateChange = event => {
    this.setState({
      apcCollectionDate: event.target.value,
    }, () => this.handleApcCourierServiceSubmit(this.state.shippingCourier))
  }

  handleApcShipOrderSubmit = () => {
    const { shipOrderAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      apcCollectionDate,
      apcServiceCode,
      shippingInstructions,
      shippingItems,
      shippingParcels,
      clearWarehouseInstructions,
    } = this.state
    const skuCodes = map(prop('sku'))(shippingItems)
    shipOrderAttempt(shippingCourier, {
      order_reference: orderReference,
      collection_date: apcCollectionDate,
      service_code: apcServiceCode,
      instructions: shippingInstructions,
      sku: skuCodes,
      parcels: shippingParcels,
      clear_warehouse_instructions: ~~clearWarehouseInstructions,
    })
  }

  // DHL
  handleDhlCourierServiceSubmit = () => {
    const { getCourierServicesAttempt } = this.props
    const {
      shippingCourier,
    } = this.state
    getCourierServicesAttempt(shippingCourier)
  }

  handleDhlShipOrderSubmit = () => {
    const { shipOrderAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      dhlCollectionJobNumber,
      dhlServiceCode,
      dhlInstructions1,
      dhlInstructions2,
      dhlSafePlace1,
      dhlSafePlace2,
      shippingItems,
      shippingParcels,
      clearWarehouseInstructions,
    } = this.state
    const skuCodes = map(prop('sku'))(shippingItems)
    shipOrderAttempt(shippingCourier, {
      order_reference: orderReference,
      collection_job_number: dhlCollectionJobNumber,
      service_code: dhlServiceCode,
      special_instructions_1: dhlInstructions1,
      special_instructions_2: dhlInstructions2,
      signature_optional: dhlServiceCode === '210' ? true : false,
      secure_location_1: dhlSafePlace1,
      secure_location_2: dhlSafePlace2,
      sku: skuCodes,
      parcels: shippingParcels,
      clear_warehouse_instructions: ~~clearWarehouseInstructions,
    })
  }

  // DPD
  handleDpdCourierServiceSubmit = () => {
    const { getCourierServicesAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      shippingParcels,
      dpdCollectionDate,
    } = this.state
    this.setState({
      shippingParcelsChanged: false
    })
    getCourierServicesAttempt(shippingCourier, {
      order_reference: orderReference,
      collection_date: dpdCollectionDate,
      parcels: shippingParcels,
    })
  }

  handleDpdCollectionDateChange = event => {
    this.setState({
      dpdCollectionDate: event.target.value,
    }, () => this.handleDpdCourierServiceSubmit(this.state.shippingCourier))
  }

  handleDpdSmsNotificationsChange = event => {
    this.setState({
      dpdSmsNotifications: event.target.checked,
    })
  }

  handleDpdExtendedLiabilityChange = event => {
    this.setState({
      dpdExtendedLiability: event.target.checked,
    })
  }

  handleDpdShipOrderSubmit = () => {
    const { shipOrderAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      dpdCollectionDate,
      dpdServiceCode,
      dpdSmsNotifications,
      dpdExtendedLiability,
      shippingInstructions,
      shippingItems,
      shippingParcels,
      clearWarehouseInstructions,
    } = this.state
    const skuCodes = map(prop('sku'))(shippingItems)
    shipOrderAttempt(shippingCourier, {
      order_reference: orderReference,
      collection_date: dpdCollectionDate,
      service_code: dpdServiceCode,
      sms_notifications: ~~dpdSmsNotifications,
      extended_liability: ~~dpdExtendedLiability,
      instructions: shippingInstructions,
      sku: skuCodes,
      parcels: shippingParcels,
      clear_warehouse_instructions: ~~clearWarehouseInstructions,
    })
  }

  // UPS
  handleUpsShipOrderSubmit = () => {
    const { shipOrderAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      shippingItems,
      shippingParcels,
      clearWarehouseInstructions,
    } = this.state
    const skuCodes = map(prop('sku'))(shippingItems)
    shipOrderAttempt(shippingCourier, {
      order_reference: orderReference,
      sku: skuCodes,
      parcels: shippingParcels,
      clear_warehouse_instructions: ~~clearWarehouseInstructions,
    })
  }

  // Parcelforce
  handleParcelforceCourierServiceSubmit = () => {
    const { getCourierServicesAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
    } = this.state
    getCourierServicesAttempt(shippingCourier, {
      order_reference: orderReference,
    })
  }

  handleParcelforceCollectionDateChange = event => {
    this.setState({
      parcelforceCollectionDate: event.target.value,
    })
  }

  handleParcelforceSaturdayDeliveryChange = event => {
    this.setState({
      parcelforceSaturdayDelivery: event.target.checked,
    })
  }

  handleParcelforceSmsNotificationsChange = event => {
    this.setState({
      parcelforceSmsNotifications: event.target.checked,
    })
  }

  handleParcelforceEnhancedCompensationChange = event => {
    this.setState({
      parcelforceEnhancedCompensation: event.target.value,
    })
  }

  handleParcelforceShipOrderSubmit = () => {
    const { shipOrderAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      parcelforceCollectionDate,
      parcelforceServiceCode,
      parcelforceSaturdayDelivery,
      parcelforceSmsNotifications,
      parcelforceEnhancedCompensation,
      shippingInstructions,
      shippingItems,
      shippingParcels,
      clearWarehouseInstructions,
    } = this.state
    const skuCodes = map(prop('sku'))(shippingItems)
    shipOrderAttempt(shippingCourier, {
      order_reference: orderReference,
      collection_date: parcelforceCollectionDate,
      service_code: parcelforceServiceCode,
      saturday_delivery: +parcelforceSaturdayDelivery,
      sms_notifications: +parcelforceSmsNotifications,
      enhanced_compensation: parcelforceEnhancedCompensation,
      instructions: shippingInstructions,
      sku: skuCodes,
      parcels: shippingParcels,
      clear_warehouse_instructions: ~~clearWarehouseInstructions,
    })
  }

  // Packfleet
  handlePackfleetCourierServiceSubmit = () => {
    const { getCourierServicesAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      shippingParcels,
      packfleetCollectionDate,
    } = this.state
    this.setState({
      shippingParcelsChanged: false
    })
    getCourierServicesAttempt(shippingCourier, {
      order_reference: orderReference,
      collection_date: packfleetCollectionDate,
      parcels: shippingParcels,
    })
  }

  handlePackfleetCollectionChange = event => {
    let collectionDate = this.state.packfleetCollectionDate
    const collection = findById(event.target.value, this.props.courierCollections)
    if (!isEmpty(collection)) collectionDate = prop('date', collection)

    this.setState({
      packfleetCollectionDate: collectionDate,
      packfleetCollectionId: event.target.value
    }, () => this.handlePackfleetCourierServiceSubmit(this.state.shippingCourier))
  }

  handlePackfleetDeliveryDateChange = event => {
    this.setState({
      packfleetDeliveryDate: event.target.value,
    })
  }

  handlePackfleetSmsNotificationsChange = event => {
    this.setState({
      packfleetSmsNotifications: event.target.checked,
    })
  }

  // NOTE extended liability not supported via API
  handlePackfleetExtendedLiabilityChange = event => {
    this.setState({
      packfleetExtendedLiability: event.target.checked,
    })
  }

  handlePackfleetPinCodeChange = event => {
    this.setState({
      packfleetPinCode: event.target.checked,
    })
  }

  handlePackfleetShipOrderSubmit = () => {
    const { courierServices, shipOrderAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      packfleetCollectionId,
      packfleetDeliveryDate,
      packfleetServiceCode,
      packfleetSmsNotifications,
      packfleetExtendedLiability,
      packfleetPinCode,
      shippingInstructions,
      shippingItems,
      shippingParcels,
      clearWarehouseInstructions,
    } = this.state
    const serviceType = split('-', packfleetServiceCode)
    const serviceName = pipe(
      find(propEq('code', packfleetServiceCode)),
      propOr('', 'name')
    )(courierServices)
    const skuCodes = map(prop('sku'))(shippingItems)

    shipOrderAttempt(shippingCourier, {
      order_reference: orderReference,
      collection_id: packfleetCollectionId,
      delivery_date: packfleetDeliveryDate,
      service_type: head(serviceType),
      service_name: serviceName,
      latest_time: pipe(tail, head, defaultTo(''))(serviceType),
      sms_notifications: ~~packfleetSmsNotifications,
      extended_liability: ~~packfleetExtendedLiability,
      confirmation_code: ~~packfleetPinCode,
      instructions: shippingInstructions,
      sku: skuCodes,
      parcels: shippingParcels,
      clear_warehouse_instructions: ~~clearWarehouseInstructions,
    })
  }

  // Offline
  handleOfflineShipOrderSubmit = () => {
    const { shipOrderAttempt } = this.props
    const {
      orderReference,
      shippingCourier,
      shippingItems,
      shippingParcels,
      clearWarehouseInstructions,
    } = this.state
    const skuCodes = map(prop('sku'))(shippingItems)
    shipOrderAttempt(shippingCourier, {
      order_reference: orderReference,
      sku: skuCodes,
      parcels: shippingParcels,
      clear_warehouse_instructions: ~~clearWarehouseInstructions,
    })
  }

  handleSyncOrderSubmit = () => {
    const { data, syncOrderAttempt } = this.props
    const { id: orderId } = data
    syncOrderAttempt(orderId)
  }

  handleDeleteOrderSubmit = () => {
    const { data, deleteOrderAttempt } = this.props
    const { unreserveProducts } = this.state
    const { id: orderId } = data
    this.toggleDeleting()
    deleteOrderAttempt(orderId, unreserveProducts)
  }

  // Labels
  handleGetShipmentLabels = (courier, shipmentId) => {
    const { getOrderShipmentLabelsAttempt } = this.props
    getOrderShipmentLabelsAttempt(courier, shipmentId)
  }

  // Documents
  handleGetShipmentDocuments = (courier, shipmentId) => {
    const { getOrderShipmentDocumentsAttempt } = this.props
    getOrderShipmentDocumentsAttempt(courier, shipmentId)
  }

  // Grid
  headerCellComponent = cellProps => {
    const columnName = path(['column', 'name'], cellProps)

    // No label and sorting
    if (equals(columnName, 'action')) {
      return <Table.Cell />
    }

    return <TableHeaderRow.Cell {...cellProps} />
  }

  rowComponent = rowProps => {
    return (
      <Table.Row hover {...rowProps} />
    )
  }

  cellComponent = ({ style, ...cellProps }) => {
    const columnName = path(['column', 'name'], cellProps)
    let cellStyle = style

    // Wrap cell
    if (contains(columnName, [
      'tracking', 'comment'
    ])) {
      cellStyle = {
        ...cellStyle,
        whiteSpace: 'pre-line',
      }
    }

    // Indicate delivery status
    if (equals(columnName, 'delivered_at')) {
      const statusIconStyle = {
        verticalAlign: 'inherit',
        pointerEvents: 'none',
        fontSize: 22
      }
      const delivered = path(['row', 'delivered_at'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          {
            !!delivered
            ? (
              <IconDone
                style={statusIconStyle}
                color="primary"
              />
            )
            : (
              <IconFlightTakeoff
                style={statusIconStyle}
                color="disabled"
              />
            )
          }
        </Table.Cell>
      )
    }

    // Labels
    if (equals(columnName, 'labels') && cellProps.value) {
      const shipmentId = path(['row', 'id'], cellProps)
      const courier = path(['row', 'courier_id'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <Tooltip
            id={`tooltip-labels-${shipmentId}`}
            title="Download labels"
            placement="left"
          >
            <IconButton onClick={() => this.handleGetShipmentLabels(courier, shipmentId)}>
              <IconPrint color="action" />
            </IconButton>
          </Tooltip>
        </Table.Cell>
      )
    }

    // Documents
    if (equals(columnName, 'documents') && cellProps.value) {
      const shipmentId = path(['row', 'id'], cellProps)
      const courier = path(['row', 'courier_id'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <Tooltip
            id={`tooltip-documents-${shipmentId}`}
            title="Download documents"
            placement="left"
          >
            <IconButton onClick={() => this.handleGetShipmentDocuments(courier, shipmentId)}>
              <IconPrint color="action" />
            </IconButton>
          </Tooltip>
        </Table.Cell>
      )
    }

    // Actions
    if (equals(columnName, 'action')) {
      const { location: routerLocation } = this.props
      const pathname = prop('pathname', routerLocation)
      const rowId = path(['row', 'id'], cellProps)
      const shipmentReference = path(['row', 'shipment_reference'], cellProps)

      return (
        <Table.Cell style={cellStyle} {...cellProps}>
          <LinkButton
            title={`Open shipment ${shipmentReference}`}
            to={{
              pathname: `/shipments/${rowId}`,
              state: { from: pathname }
            }}
          >
            <IconVisibility color="action" />
          </LinkButton>
        </Table.Cell>
      )
    }

    return <Table.Cell style={cellStyle} {...cellProps} />
  }

  cellComponentNoData = (colSpan, message) => {
    return (
      <Table.Cell colSpan={colSpan}>
        {<Typography variant="body1" align="center">{message}</Typography>}
      </Table.Cell>
    )
  }

  render() {
    const {
      classes,
      history: routerHistory,
      goBack,
      fullScreen,
      // Grid
      uiLoadingGet,
      uiLoadingChangeStatus,
      uiLoadingAdjust,
      uiLoadingCourier,
      uiLoadingShip,
      uiLoadingLabels,
      uiLoadingDocuments,
      uiLoadingSync,
      uiLoadingDelete,
      orderStatuses,
      couriers,
      data,
      courierServices,
      courierCollections,
      error,
      errors,
      orderReservedColumns,
      orderItemsColumns,
      orderItemsSorting,
      onOrderItemsSortingChange,
      orderItemsSelection,
      onOrderItemsSelectionChange,
      orderItemsColumnExtensions,
      orderShipments,
      orderShipmentsColumns,
      orderShipmentsColumnExtensions,
      orderHistory,
      orderHistoryColumns,
      orderHistoryPageSize,
    } = this.props
    const {
      // Order details
      id: orderId,
      backlink: orderBacklink,
      order_reference: orderReference,
      status: orderStatus,
      order_date: orderDate,
      updated_at: dateUpdated,
      order_status_id: orderStatusId,
      customer_billing_contact: customer,
      customer_email: customerEmail,
      customer_phone_number: customerTelephone,
      gift_message: giftMessage,
      warehouse_instructions: warehouseInstructions,
      delivery_date: deliveryDate,
      // Delivery address
      customer_delivery_company: deliveryCompany,
      customer_delivery_contact: deliveryContact,
      customer_delivery_address_line1: deliveryAddress1,
      customer_delivery_address_line2: deliveryAddress2,
      customer_delivery_city: deliveryCity,
      customer_delivery_county: deliveryCounty,
      customer_delivery_postcode: deliveryPostcode,
      customer_delivery_country: deliveryCountry,
      customer_delivery_phone_number: deliveryTelephone,
      delivery_instructions: deliveryInstructions,
      order_items: orderItems,
      reserved_products: reservedProducts
    } = data
    const {
      orderReference: reference,
      changingStatus,
      orderStatusId: statusId,
      orderStatusComment,
      allocateItems,
      deallocateItems,
      shippingOptionsEl,
      shippingCourier,
      shippingItems,
      shippingParcels,
      shippingParcelsChanged,
      shippingInstructions,
      clearWarehouseInstructions,
      apcCollectionDate,
      apcServiceCode,
      dhlCollectionJobNumber,
      dhlServiceCode,
      dhlInstructions1,
      dhlInstructions2,
      dhlSafePlace1,
      dhlSafePlace2,
      dpdCollectionDate,
      dpdServiceCode,
      dpdSmsNotifications,
      dpdExtendedLiability,
      parcelforceCollectionDate,
      parcelforceServiceCode,
      parcelforceSaturdayDelivery,
      parcelforceSmsNotifications,
      parcelforceEnhancedCompensation,
      packfleetCollectionId,
      packfleetDeliveryDate,
      packfleetServiceCode,
      packfleetSmsNotifications,
      packfleetExtendedLiability,
      packfleetPinCode,
      deleting,
      unreserveProducts
    } = this.state
    // NOTE UK specific..
    const postcodeArea = pipe(split(' '), head)(deliveryPostcode || '')
    const collectionPostcode = process.env.REACT_APP_COLLECTION_POSTCODE
    let collectionWarning = false
    let possibleCouriers = []
    map(courier => {
      const coverage = propOr([], 'coverage_postcodes', courier)
      if (courier.id === 'offline' && deliveryPostcode === collectionPostcode) {
        collectionWarning = true
        // TODO global helper and Collection not Offline
        possibleCouriers.push('Offline')
      } else if (!collectionWarning && contains(postcodeArea, coverage)) {
        possibleCouriers.push(courier.name)
      }
    }, couriers)

    let loading = null

    if (uiLoadingAdjust) {
      loading = 'Adjusting stock...'
    } else if (uiLoadingLabels) {
      loading = 'Downloading labels...'
    } else if (uiLoadingDocuments) {
      loading = 'Downloading documents...'
    } else if (uiLoadingGet) {
      loading = 'Loading order...'
    } else if (uiLoadingSync) {
      loading = 'Syncing order...'
    } else if (uiLoadingDelete) {
      loading = 'Deleting order...'
    }

    let titleProps = null
    if (orderStatus) {
      titleProps = {
        chip: orderStatus,
        onChipClick: this.toggleChangingStatus,
      }
    }

    let CourierForm
    switch (shippingCourier) {
      case 'apc':
        CourierForm = (
          <CourierFormApc
            uiLoading={uiLoadingCourier || uiLoadingShip}
            onReload={this.handleApcCourierServiceSubmit}
            collectionDate={apcCollectionDate}
            onCollectionDateChange={this.handleApcCollectionDateChange}
            services={courierServices}
            onServicesChange={this.handleInputChange('apcServiceCode')}
            serviceCode={apcServiceCode}
            parcels={shippingParcels}
            parcelsChanged={shippingParcelsChanged}
            onParcelChange={this.handleParcelChange}
            onAddParcel={this.handleAddParcel}
            onRemoveParcel={this.handleRemoveParcel}
            instructions={shippingInstructions}
            onInstructionsChange={this.handleInputChange('shippingInstructions')}
            onCancel={this.toggleShippingClose}
            onSubmit={this.handleApcShipOrderSubmit}
          />
        )
        break
      case 'dhl':
        CourierForm = (
          <CourierFormDhl
            uiLoading={uiLoadingCourier || uiLoadingShip}
            collectionJobNumber={dhlCollectionJobNumber}
            onCollectionJobNumberChange={this.handleInputChange('dhlCollectionJobNumber')}
            services={courierServices}
            onServicesChange={this.handleInputChange('dhlServiceCode')}
            serviceCode={dhlServiceCode}
            parcels={shippingParcels}
            onParcelChange={this.handleParcelChange}
            onAddParcel={this.handleAddParcel}
            onRemoveParcel={this.handleRemoveParcel}
            instructions={deliveryInstructions}
            instructions1={dhlInstructions1}
            onInstructions1Change={this.handleInputChange('dhlInstructions1')}
            instructions2={dhlInstructions2}
            onInstructions2Change={this.handleInputChange('dhlInstructions2')}
            safePlace1={dhlSafePlace1}
            onSafePlace1Change={this.handleInputChange('dhlSafePlace1')}
            safePlace2={dhlSafePlace2}
            onSafePlace2Change={this.handleInputChange('dhlSafePlace2')}
            onCancel={this.toggleShippingClose}
            onSubmit={this.handleDhlShipOrderSubmit}
          />
        )
        break
      case 'dpd':
        CourierForm = (
          <CourierFormDpd
            uiLoading={uiLoadingCourier || uiLoadingShip}
            onReload={this.handleDpdCourierServiceSubmit}
            collectionDate={dpdCollectionDate}
            onCollectionDateChange={this.handleDpdCollectionDateChange}
            services={courierServices}
            onServicesChange={this.handleInputChange('dpdServiceCode')}
            serviceCode={dpdServiceCode}
            extendedLiability={dpdExtendedLiability}
            smsNotifications={dpdSmsNotifications}
            onSmsNotificationsChange={this.handleDpdSmsNotificationsChange}
            onExtendedLiabilityChange={this.handleDpdExtendedLiabilityChange}
            parcels={shippingParcels}
            parcelsChanged={shippingParcelsChanged}
            onParcelChange={this.handleParcelChange}
            onAddParcel={this.handleAddParcel}
            onRemoveParcel={this.handleRemoveParcel}
            instructions={shippingInstructions}
            onInstructionsChange={this.handleInputChange('shippingInstructions')}
            onCancel={this.toggleShippingClose}
            onSubmit={this.handleDpdShipOrderSubmit}
          />
        )
        break
      case 'ups':
        CourierForm = (
          <CourierFormUps
            uiLoading={uiLoadingCourier || uiLoadingShip}
            parcels={shippingParcels}
            onParcelChange={this.handleParcelChange}
            onAddParcel={this.handleAddParcel}
            onRemoveParcel={this.handleRemoveParcel}
            onCancel={this.toggleShippingClose}
            onSubmit={this.handleUpsShipOrderSubmit}
          />
        )
        break
      case 'parcelforce':
        CourierForm = (
          <CourierFormParcelforce
            uiLoading={uiLoadingCourier || uiLoadingShip}
            collectionDate={parcelforceCollectionDate}
            onCollectionDateChange={this.handleParcelforceCollectionDateChange}
            services={courierServices}
            onServicesChange={this.handleInputChange('parcelforceServiceCode')}
            serviceCode={parcelforceServiceCode}
            saturdayDelivery={parcelforceSaturdayDelivery}
            onSaturdayDeliveryChange={this.handleParcelforceSaturdayDeliveryChange}
            smsNotifications={parcelforceSmsNotifications}
            onSmsNotificationsChange={this.handleParcelforceSmsNotificationsChange}
            enhancedCompensation={parcelforceEnhancedCompensation}
            onEnhancedCompensationChange={this.handleParcelforceEnhancedCompensationChange}
            parcels={shippingParcels}
            onParcelChange={this.handleParcelChange}
            onAddParcel={this.handleAddParcel}
            onRemoveParcel={this.handleRemoveParcel}
            instructions={shippingInstructions}
            onInstructionsChange={this.handleInputChange('shippingInstructions')}
            onCancel={this.toggleShippingClose}
            onSubmit={this.handleParcelforceShipOrderSubmit}
          />
        )
        break
      case 'packfleet':
        CourierForm = (
          <CourierFormPackfleet
            uiLoading={uiLoadingCourier || uiLoadingShip}
            onReload={this.handlePackfleetCourierServiceSubmit}
            collections={courierCollections}
            collectionId={packfleetCollectionId}
            onCollectionChange={this.handlePackfleetCollectionChange}
            deliveryDate={packfleetDeliveryDate}
            onDeliveryDateChange={this.handlePackfleetDeliveryDateChange}
            services={courierServices}
            onServicesChange={this.handleInputChange('packfleetServiceCode')}
            serviceCode={packfleetServiceCode}
            smsNotifications={packfleetSmsNotifications}
            onSmsNotificationsChange={this.handlePackfleetSmsNotificationsChange}
            extendedLiability={packfleetExtendedLiability}
            onExtendedLiabilityChange={this.handlePackfleetExtendedLiabilityChange}
            pinCode={packfleetPinCode}
            onPinCodeChange={this.handlePackfleetPinCodeChange}
            parcels={shippingParcels}
            parcelsChanged={shippingParcelsChanged}
            onParcelChange={this.handleParcelChange}
            onAddParcel={this.handleAddParcel}
            onRemoveParcel={this.handleRemoveParcel}
            instructions={shippingInstructions}
            onInstructionsChange={this.handleInputChange('shippingInstructions')}
            onCancel={this.toggleShippingClose}
            onSubmit={this.handlePackfleetShipOrderSubmit}
          />
        )
        break
      case 'offline':
        CourierForm = (
          <CourierFormOffline
            uiLoading={uiLoadingCourier || uiLoadingShip}
            parcels={shippingParcels}
            onParcelChange={this.handleParcelChange}
            onAddParcel={this.handleAddParcel}
            onRemoveParcel={this.handleRemoveParcel}
            onCancel={this.toggleShippingClose}
            onSubmit={this.handleOfflineShipOrderSubmit}
          />
        )
        break
      default:
        CourierForm = null
        break
    }

    return (
      <div className={classes.container}>
        <Header
          title={`Order #${reference}`}
          subtitle={`Manage order`}
          {...titleProps}
        >
          <GoBackButton
            onBack={goBack}
            history={routerHistory}
          />
          <RefreshButton
            onClick={this.handleMount}
          />
          {orderId && (
            <Tooltip
              id="tooltip-print"
              title="Print order"
              placement="left"
            >
              <IconButton>
                <PrintButton
                  trigger={() => <IconPrint />}
                  content={() => this.printComponent}
                />
              </IconButton>
            </Tooltip>
          )}
          {orderId && orderBacklink && (
            <Tooltip
              id="tooltip-backlink"
              title="Order backlink"
              placement="left"
            >
              <IconButton
                onClick={() => window.open(orderBacklink, '_blank')}
              >
                <IconLaunch color="action" />
              </IconButton>
            </Tooltip>
          )}
          {orderId && (
            <Tooltip
              id="tooltip-sync"
              title="Sync order"
              placement="left"
            >
              <IconButton onClick={this.handleSyncOrderSubmit}>
                <IconSync color="action" />
              </IconButton>
            </Tooltip>
          )}
          {orderId && (
            <Tooltip
              id="tooltip-delete"
              title="Delete order"
              placement="left"
            >
              <IconButton onClick={this.toggleDeleting}>
                <IconDelete color="error" />
              </IconButton>
            </Tooltip>
          )}
        </Header>
        {
          orderId
          ? (
            <Grid container spacing={3}>
              {/* Order Info */}
              <Grid item xs={12} sm={6}>
                <Paper className={join(' ', [classes.paper, classes.column])}>
                  <Toolbar disableGutters>
                    <Typography className={classes.flex} variant="subtitle1">
                      {'Order Details'}
                    </Typography>
                  </Toolbar>
                  <Divider className={classes.divider} />
                  <TextField
                    id="orderId"
                    label="Order ID"
                    fullWidth
                    value={orderId}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="orderReference"
                    label="Order Reference"
                    fullWidth
                    value={orderReference}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    // type="date"
                    id="orderDate"
                    label="Order Date"
                    fullWidth
                    value={formatDate(orderDate)}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    // type="date"
                    id="dateUpdated"
                    label="Date Updated"
                    fullWidth
                    value={formatDate(dateUpdated)}
                    margin="dense"
                    disabled
                  />
                  <TextField
                    id="customer"
                    label="Customer"
                    fullWidth
                    value={customer}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="customerEmail"
                    label="Customer Email"
                    fullWidth
                    value={customerEmail}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="customerTelephone"
                    label="Customer Telephone"
                    fullWidth
                    value={customerTelephone}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    error={giftMessage ? true : false}
                    id="giftMessage"
                    label="Gift Message"
                    fullWidth
                    value={giftMessage}
                    margin="dense"
                    multiline
                    readOnly
                  />
                  <TextField
                    error={warehouseInstructions ? true : false}
                    id="warehouseInstructions"
                    label="Warehouse Instructions"
                    fullWidth
                    value={warehouseInstructions}
                    margin="dense"
                    multiline
                    readOnly
                  />
                  <TextField
                    error={deliveryDate ? true : false}
                    // type="date"
                    id="deliveryDate"
                    label="Delivery Date"
                    fullWidth
                    value={formatDate(deliveryDate, 'dddd, MMM Do YYYY')}
                    margin="dense"
                    disabled
                  />
                </Paper>
              </Grid>
              {/* Order Delivery */}
              <Grid item xs={12} sm={6}>
                <Paper className={join(' ', [classes.paper, classes.column])}>
                  <Toolbar disableGutters>
                    <Typography className={classes.flex} variant="subtitle1">
                      {'Delivery Address'}
                    </Typography>
                  </Toolbar>
                  <Divider className={classes.divider} />
                  <TextField
                    id="deliveryCompany"
                    label="Company"
                    fullWidth
                    value={deliveryCompany}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="deliveryContact"
                    label="Contact"
                    fullWidth
                    value={deliveryContact}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="deliveryAddress1"
                    label="Address Line 1"
                    fullWidth
                    value={deliveryAddress1}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="deliveryAddress2"
                    label="Address Line 2"
                    fullWidth
                    value={deliveryAddress2}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="deliveryCity"
                    label="City"
                    fullWidth
                    value={deliveryCity}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="deliveryCounty"
                    label="County"
                    fullWidth
                    value={deliveryCounty}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="deliveryPostcode"
                    label="Postcode"
                    fullWidth
                    value={deliveryPostcode}
                    margin="dense"
                    readOnly
                    helperText={!isEmpty(possibleCouriers) ? `(${join(', ', possibleCouriers)})` : ''}
                    InputLabelProps={{
                      className: !isEmpty(possibleCouriers) ? classes.warning : null
                    }}
                    FormHelperTextProps={{
                      className: !isEmpty(possibleCouriers) ? `${classes.warning} ${classes.italic}` : null
                    }}
                    InputProps={{
                      startAdornment: !isEmpty(possibleCouriers) ? (
                        <InputAdornment position="start">
                          <IconWarning color="disabled" />
                        </InputAdornment>
                      ) : null
                    }}
                  />
                  <TextField
                    id="deliveryCountry"
                    label="Country"
                    fullWidth
                    value={deliveryCountry}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="deliveryTelephone"
                    label="Telephone"
                    fullWidth
                    value={deliveryTelephone}
                    margin="dense"
                    readOnly
                  />
                  <TextField
                    id="deliveryInstructions"
                    label="Instructions"
                    fullWidth
                    value={deliveryInstructions}
                    margin="dense"
                    multiline
                    readOnly
                  />
                </Paper>
              </Grid>
              {/* Reserved Items */}
              {!isEmpty(reservedProducts) && (
                <Grid item xs={12}>
                  <Paper className={join(' ', [classes.paper, classes.paperReserved])}>
                    <Toolbar disableGutters>
                      <Typography className={classes.flex} variant="subtitle1">
                        {'Reserved'}
                      </Typography>
                    </Toolbar>
                    <Divider className={classes.divider} />
                    <GridOrderItems
                      rows={reservedProducts}
                      columns={orderReservedColumns.asMutable()}
                      columnExtensions={orderItemsColumnExtensions.asMutable()}
                    />
                  </Paper>
                </Grid>
              )}
              {/* Order Items */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Toolbar disableGutters>
                    <Typography className={classes.flex} variant="subtitle1">
                      {'Order Items'}
                    </Typography>
                  </Toolbar>
                  <Divider className={classes.divider} />
                  <GridOrderItems
                    rows={orderItems}
                    columns={orderItemsColumns.asMutable()}
                    columnExtensions={orderItemsColumnExtensions.asMutable()}
                    sorting={orderItemsSorting.asMutable()}
                    onSortingChange={onOrderItemsSortingChange}
                    selection={orderItemsSelection.asMutable()}
                    onSelectionChange={onOrderItemsSelectionChange}
                    rowSelectionEnabled={rowSelectionEnabled}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} className={classes.buttons}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  onClick={this.handleAllocateSubmit}
                  disabled={length(allocateItems) ? false : true}
                >
                  {`Allocate (${length(allocateItems)})`}
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  onClick={this.handleDeallocateSubmit}
                  disabled={length(deallocateItems) ? false : true}
                >
                  {`Deallocate (${length(deallocateItems)})`}
                </Button>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={this.toggleShippingOptions}
                  disabled={length(shippingItems) ? false : true}
                >
                  {`Ship (${length(shippingItems)})`}
                </Button>
                <Menu
                  anchorEl={shippingOptionsEl}
                  open={Boolean(shippingOptionsEl)}
                  onClose={this.toggleShippingOptions}
                >
                  {couriers.map(courier => {
                    return (
                      <MenuItem
                        key={courier.id}
                        onClick={() => this.toggleShippingOpen(courier.id)}
                        disabled={length(shippingItems) ? false : true}
                      >
                        {
                          (contains(courier.name, possibleCouriers))
                          ? (
                            <IconWarning className={classes.courierWarningIcon} color="disabled" />
                          )
                          : null
                        }
                        {courier.name}
                      </MenuItem>
                    )
                  })}
                </Menu>
              </Grid>
              {/* Shipments */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Toolbar disableGutters>
                    <Typography className={classes.flex} variant="subtitle1">
                      {'Order Shipments'}
                    </Typography>
                  </Toolbar>
                  <Divider className={classes.divider} />
                  <ReactGrid
                    rows={orderShipments}
                    columns={orderShipmentsColumns.asMutable()}
                  >
                    <GridDateTypeProvider
                      columns={['created_at']}
                    />
                    <GridCourierTrackingTypeProvider
                      columns={['courier_reference']}
                    />
                    <Table
                      columnExtensions={orderShipmentsColumnExtensions.asMutable()}
                      rowComponent={this.rowComponent}
                      cellComponent={this.cellComponent}
                      noDataCellComponent={() =>
                        this.cellComponentNoData(
                          length(orderShipmentsColumns),
                          'No shipments.'
                        )
                      }
                    />
                    <TableHeaderRow
                      cellComponent={this.headerCellComponent}
                    />
                  </ReactGrid>
                </Paper>
              </Grid>
              {/* History */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Toolbar disableGutters>
                    <Typography className={classes.flex} variant="subtitle1">
                      {'Order History'}
                    </Typography>
                    <Tooltip
                      id="tooltip-change-status"
                      title="Change order status"
                      placement="left"
                    >
                      <IconButton onClick={this.toggleChangingStatus}>
                        <IconAdd />
                      </IconButton>
                    </Tooltip>
                  </Toolbar>
                  <Divider className={classes.divider} />
                  <ReactGrid
                    rows={orderHistory}
                    columns={orderHistoryColumns.asMutable()}
                  >
                    <GridDateTypeProvider
                      columns={['created_at']}
                    />
                    <GridUrlTypeProvider
                      columns={['comment']}
                    />
                    <PagingState
                      defaultCurrentPage={0}
                      pageSize={orderHistoryPageSize}
                    />
                    <IntegratedPaging />
                    <Table
                      rowComponent={this.rowComponent}
                      cellComponent={this.cellComponent}
                      noDataCellComponent={() =>
                        this.cellComponentNoData(
                          length(orderHistoryColumns),
                          'No history.'
                        )
                      }
                    />
                    <TableHeaderRow />
                    <PagingPanel />
                  </ReactGrid>
                </Paper>
              </Grid>
            </Grid>
          )
          : (
            error && <Empty message={error || 'No data.'} />
          )
        }
        {orderId && (
          <DialogOrderStatusChange
            open={changingStatus}
            onClose={this.toggleChangingStatus}
            disableBackdropClick={uiLoadingChangeStatus}
            disableEscapeKeyDown={uiLoadingChangeStatus}
            statuses={orderStatuses}
            defaultStatusId={orderStatusId}
            statusId={Number(statusId)}
            comment={orderStatusComment}
            onChange={this.handleInputChange}
            onSubmit={this.handleStatusChangeSubmit}
            submitting={uiLoadingChangeStatus}
          />
        )}
        {orderId && (
          <Dialog
            fullScreen={fullScreen}
            fullWidth
            maxWidth="md"
            open={!!shippingCourier}
            onExited={() => {
              this.setState({
                apcServiceCode: '',
                dhlServiceCode: '',
                dpdServiceCode: '',
                parcelforceServiceCode: '',
                packfleetServiceCode: ''
              })
              this.props.resetCourierServices()
            }}
            onClose={this.toggleShippingClose}
            disableBackdropClick={uiLoadingShip}
            disableEscapeKeyDown={uiLoadingShip}
          >
            <DialogTitle>{`Create Order Shipment (${toUpper(shippingCourier || '')})`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You are about to ship {length(shippingItems)} product(s):<br />
                {shippingItems.map(product => (
                  <span key={product.id}>
                    {`${product.allocated} x ${product.sku} - ${product.description}`}
                    <br />
                  </span>
                ))}
              </DialogContentText>
            </DialogContent>
            <DialogContent>
              {
                !isEmpty(errors)
                ? (
                  <DialogContent>
                    <Typography color="error" variant="subtitle1" gutterBottom>
                      {`${length(values(errors))}x Error(s)`}
                    </Typography>
                    {values(errors).map((error, key) => (
                      <Typography key={`ship-error-${key}`} color="error">- {error}</Typography>
                    ))}
                  </DialogContent>
                )
                : null
              }
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <DialogContent>
                    {[
                      deliveryCompany,
                      deliveryContact,
                      deliveryAddress1,
                      deliveryAddress2,
                      deliveryCity,
                      deliveryCounty,
                      deliveryPostcode,
                      deliveryCountry,
                      deliveryTelephone
                    ].map((address, key) => (
                      <Typography key={`ship-address-${key}`} variant="body1" gutterBottom>
                        {address}
                      </Typography>
                    ))}
                    {warehouseInstructions && (
                      <Typography variant="subtitle1" color="error" gutterBottom className={classes.gutterTop}>
                        {'Warehouse Instructions'}
                      </Typography>
                    )}
                    {warehouseInstructions && nl2brArray(warehouseInstructions).map((text, key) => (
                      <Typography key={key} color="error" gutterBottom>{text}</Typography>
                    ))}
                    {warehouseInstructions && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={clearWarehouseInstructions}
                            onChange={this.handleClearWarehouseInstructionsChange}
                            value="clearWarehouseInstructions"
                          />
                        }
                        label="Clear warehouse instructions"
                      />
                    )}
                  </DialogContent>
                </Grid>
                <Grid item xs={12} sm={8} className={classes.courierForm}>
                  {CourierForm}
                </Grid>
              </Grid>
            </DialogContent>
            {uiLoadingCourier && (
              <Loading
                variant="absolute"
                message="Loading courier services..."
              />
            )}
          </Dialog>
        )}
        {orderId && (
          <PrintWrapper ref={c => (this.printComponent = c)}>
            <PrintOrder
              order={data}
              couriers={couriers}
            />
          </PrintWrapper>
        )}
        {orderId && (
          <Dialog
            open={deleting}
            onClose={this.toggleDeleting}
          >
            <DialogTitle>Are you sure you want to delete this order?</DialogTitle>
            <DialogContent>
              {!isEmpty(reservedProducts)
                ? (
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Unreserve products?</FormLabel>
                    <FormGroup>
                      {reservedProducts.map(product => (
                        <FormControlLabel
                          key={product.id}
                          control={
                            <Checkbox
                              checked={contains(product.sku, unreserveProducts)}
                              value={product.sku}
                              onChange={this.handleUnreserveChange}
                              name="unreserveProduct"
                            />
                          }
                          label={`${product.reserved}x ${product.description}`}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                )
                : (<DialogContentText>This cannot be undone.</DialogContentText>)
              }
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleDeleting}>
                {'Cancel'}
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: '10px'}}
                color="primary"
                onClick={this.handleDeleteOrderSubmit}
              >
                {'Delete'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {loading && <Loading message={loading} />}
      </div>
    )
  }
}

Order.propTypes = {
  classes: PropTypes.object.isRequired,
  replace: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  // Grid
  getOrderAttempt: PropTypes.func.isRequired,
  changeOrderStatusAttempt: PropTypes.func.isRequired,
  adjustOrderItemsStockAttempt: PropTypes.func.isRequired,
  getCourierServicesAttempt: PropTypes.func.isRequired,
  resetCourierServices: PropTypes.func.isRequired,
  shipOrderAttempt: PropTypes.func.isRequired,
  getOrderShipmentLabelsAttempt: PropTypes.func.isRequired,
  getOrderShipmentDocumentsAttempt: PropTypes.func.isRequired,
  syncOrderAttempt: PropTypes.func.isRequired,
  deleteOrderAttempt: PropTypes.func.isRequired,
  uiLoadingGet: PropTypes.bool.isRequired,
  uiLoadingChangeStatus: PropTypes.bool.isRequired,
  uiLoadingAdjust: PropTypes.bool.isRequired,
  uiLoadingCourier: PropTypes.bool.isRequired,
  uiLoadingShip: PropTypes.bool.isRequired,
  uiLoadingSync: PropTypes.bool.isRequired,
  uiLoadingDelete: PropTypes.bool.isRequired,
  orderStatuses: PropTypes.array.isRequired,
  couriers: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  courierServices: PropTypes.array.isRequired,
  courierCollections: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  orderReservedColumns: PropTypes.array.isRequired,
  orderItemsColumns: PropTypes.array.isRequired,
  orderItemsColumnExtensions: PropTypes.array.isRequired,
  orderItemsSorting: PropTypes.array.isRequired,
  onOrderItemsSortingChange: PropTypes.func.isRequired,
  orderItemsSelection: PropTypes.array.isRequired,
  onOrderItemsSelectionChange: PropTypes.func.isRequired,
  orderShipments: PropTypes.array.isRequired,
  orderShipmentsColumns: PropTypes.array.isRequired,
  orderShipmentsColumnExtensions: PropTypes.array.isRequired,
  orderHistory: PropTypes.array.isRequired,
  orderHistoryColumns: PropTypes.array.isRequired,
  orderHistoryPageSize: PropTypes.number.isRequired,
  resetOrderState: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  ...state.order,
  data: orderSelector(state),
  orderShipments: orderShipmentsSelector(state),
  orderHistory: orderHistorySelector(state),
  orderStatuses: state.app.orderStatuses,
  couriers: activeCouriersSelector(state),
})

const {
  getOrderAttempt,
  setOrderGridState,
  changeOrderStatusAttempt,
  adjustOrderItemsStockAttempt,
  getCourierServicesAttempt,
  resetCourierServices,
  shipOrderAttempt,
  getOrderShipmentLabelsAttempt,
  getOrderShipmentDocumentsAttempt,
  syncOrderAttempt,
  deleteOrderAttempt,
  resetOrderState,
} = Actions

const mapDispatchToProps = dispatch => bindActionCreators({
  replace,
  goBack,
  getOrderAttempt,
  onOrderItemsSortingChange: sorting => setOrderGridState('orderItemsSorting', sorting),
  onOrderItemsSelectionChange: selection => setOrderGridState('orderItemsSelection', selection),
  changeOrderStatusAttempt,
  adjustOrderItemsStockAttempt,
  getCourierServicesAttempt,
  resetCourierServices,
  shipOrderAttempt,
  getOrderShipmentLabelsAttempt,
  getOrderShipmentDocumentsAttempt,
  syncOrderAttempt,
  deleteOrderAttempt,
  resetOrderState,
}, dispatch)

export default compose(
  withMobileDialog(), withStyles(styles), connect(mapStateToProps, mapDispatchToProps)
)(Order)
